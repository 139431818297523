import { useState } from 'react';

const BurgerButton = ({ classNameBurger }) => {
  const [fixed, setFixed] = useState(false);
  const heightScreen = window.innerHeight;

  const toggleVisible = () => {
    const scrolled = window.scrollY;
    if (scrolled > heightScreen) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  };

  window.addEventListener('scroll', toggleVisible);

  return <div className={classNameBurger}></div>;
};

export default BurgerButton;
