import { useEffect } from 'react';

import BlockButton from 'components/BlockButton';
import HeroImg from 'images/pictures/desktop/participer-a-l-evenement.jpg';
import HeroImgMobile from 'images/pictures/mobile/la-main-sur-le-coeur-participer-a-l-evenement-mobile.jpg';
import LegalesIcon from 'images/icons/legales.svg';

const Legales = () => {
  const widthScreen = window.innerWidth;

  useEffect(() => {
    document.title = 'Mentions légales - La Main sur le Coeur';

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className="legales-container">
      <section className="legales-hero-container">
        <img
          className="hero-img"
          src={widthScreen < 559 ? HeroImgMobile : HeroImg}
          alt="Bénévole portant le t-shirt de l'association"
        />
        <div className="title-container">
          <BlockButton
            classNameButton="hero-title bg-red white-text"
            classNameIcon="block-button-icon"
            icon={LegalesIcon}
            text="Mentions légales"
            height={50}
          />
        </div>
        <div className="legales-text">
          <p className="subtitle">Les mentions légales sont en cours de rédaction</p>
        </div>
      </section>

      <section className="text-container">
        <div className="content"></div>
      </section>
    </div>
  );
};

export default Legales;
