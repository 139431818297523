import { Routes, Route } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTopButton from 'components/ScrollToTopButton';

import Home from './pages/Home';
import Volunteer from 'pages/Volunteer';
import Give from 'pages/Give';
import Sponsor from 'pages/Sponsor';
import Event from 'pages/Event';
import Legales from 'pages/Legales';
import RGPD from 'pages/RGPD';

import './styles/index.scss';

const App = () => {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/devenir-bénévole" element={<Volunteer />} />
        <Route path="/faire-un-don" element={<Give />} />
        <Route path="/participer-au-prochain-événement" element={<Event />} />
        <Route path="/parrainer-un-enfant" element={<Sponsor />} />
        <Route path="/mentions-légales" element={<Legales />} />
        <Route path="/politique-de-confidentialité" element={<RGPD />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <ScrollToTopButton />
      <Footer />
    </div>
  );
};

export default App;
