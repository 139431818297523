import { useState } from 'react';

import Arrow from 'images/icons/suivant-bleu.svg';

const ScrollToTopButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = window.scrollY;
    if (scrolled > 1000) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <div className="scrollToTop-container" style={{ display: visible ? 'inline' : 'none' }}>
      <button className="scrollToTopBtn" onClick={scrollToTop}>
        <img className="icon" src={Arrow} />
      </button>
      <p className="text">Haut de site</p>
    </div>
  );
};

export default ScrollToTopButton;
