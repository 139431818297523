import { useState, useEffect, useRef } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useForm } from 'react-hook-form';
import * as emailjs from '@emailjs/browser';
import { init } from '@emailjs/browser';
import dotenv from 'dotenv';

import BlockButton from 'components/BlockButton';
import Button from 'components/Button';
import HeroImg from 'images/pictures/desktop/participer-a-l-evenement.jpg';
import HeroImgMobile from 'images/pictures/mobile/la-main-sur-le-coeur-participer-a-l-evenement-mobile.jpg';
import Agenda from 'images/icons/evenement-blanc.svg';

const Event = () => {
  const widthScreen = window.innerWidth;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      lastname: '',
      firstname: '',
      email: '',
      phone: '',
    },
  });

  const lastname = 'nom';
  const firstname = 'prénom';
  const email = 'email';
  const phone = 'téléphone';

  // const mailRegex =
  //   /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;

  // const phoneRegex = /(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/g;

  const [isChecked, setIsChecked] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');

  dotenv.config();
  init(process.env.REACT_APP_API_KEY_LOCAL);
  const form = useRef();

  const sendEmail = (formData, e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_LOCAL,
        process.env.REACT_APP_TPL_LOCAL,
        form.current,
        process.env.REACT_APP_API_KEY_LOCAL,
      )
      .then(
        () => {
          // console.log('YEP !', response.status, response.text);
          setIsSubmitted(true);
        },
        () => {
          // console.log('NOPE ...', error);
          setIsSubmitted(false);
          setConfirmationMessage("Oups, il semblerait qu'il y ait un problème ... \n \n Merci de réessayer plus tard.");
        },
      );

    reset();
  };

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    document.title = "Participer à l'évènement - La Main sur le Coeur";

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className="event-container">
      <section className="event-hero-container">
        <img
          className="hero-img"
          src={widthScreen < 559 ? HeroImgMobile : HeroImg}
          alt="Carton rempli de denrées alimentaires"
        />
        <div className="title-container">
          <BlockButton
            classNameButton="hero-title bg-sky-blue white-text"
            classNameIcon="block-button-icon"
            icon={Agenda}
            text="Événement"
            text2={widthScreen < 899 ? 'Voyage au Mali - 12/22' : '- Voyage au Mali - 12/22'}
            height={50}
          />
        </div>
        <div className="event-text">
          <p className="subtitle">
            Un <span className="span">voyage au Mali</span> est organisé pour voir nos filleuls que nous parrainons pour
            l&apos;année scolaire, et pour visiter deux orphelinats en faisant des dons vestimentaires et alimentaires,
            comme par exemple : du lait en poudre, des gâteaux pour les collations, et des couches.
            <br /> <br />
            Pour y participer, merci de remplir le formulaire ci-dessous pour être recontacté et pouvoir participer à
            l&apos;événement.
          </p>
        </div>
      </section>

      <section className="form-container bg-cian">
        {isSubmitted ? (
          <div className="confirmation-message-container white-text">
            <p className="message-bold">
              Votre demande pour participer à l&apos;événement a bien été envoyée. <br /> Nous vous remercions pour
              votre envie de vous engager avec nous.
            </p>
            <p className="message">
              Vous allez être redirigé(e) sur la page d&apos;accueil dans quelques secondes. <br /> Si ce n&apos;est pas
              le cas,{' '}
              <a className="home-link" href="/">
                cliquez-ici
              </a>
              .
            </p>
          </div>
        ) : (
          <form className="form" action="submit" onSubmit={handleSubmit(sendEmail)} ref={form}>
            <input
              type="hidden"
              name="reason"
              {...register('reason', {
                value: 'Le contact souhaite participer au prochain évènement !',
              })}
            />

            <ResponsiveMasonry columnsCountBreakPoints={{ 320: 1, 899: 2 }}>
              <Masonry gutter="2rem" className="grid">
                <div className="input-container">
                  <input type="text" placeholder="Nom*" {...register('lastname', { required: true })} />
                  {errors.lastname && <p className="error-message">Merci de renseigner votre {lastname}</p>}
                </div>

                <div className="input-container">
                  <input type="text" placeholder="Prénom*" {...register('firstname', { required: true })} />
                  {errors.firstname && <p className="error-message">Merci de renseigner votre {firstname}</p>}
                </div>

                <div className="input-container">
                  <input type="email" placeholder="E-mail*" {...register('email', { required: true })} />
                  {errors.email && <p className="error-message">Merci de renseigner votre {email}</p>}
                </div>

                <div className="input-container">
                  <input type="text" placeholder="Téléphone*" {...register('phone', { required: true })} />
                  {errors.phone && <p className="error-message">Merci de renseigner votre {phone}</p>}
                </div>
              </Masonry>
            </ResponsiveMasonry>

            <p className="form-mentions">* Champs obligatoires</p>

            <div className="form-checkbox">
              <input
                className="checkbox"
                type="checkbox"
                id="checkbox"
                value={isChecked}
                checked={isChecked}
                {...register('checkbox', {
                  required: true,
                  onChange: () => {
                    handleOnChange();
                  },
                })}
              />
              <label className="checkbox-text" htmlFor="checkbox">
                En cochant cette case, j&apos;accepte que La Main sur le Cœur traite mes données dans le cadre de
                l&apos;envoi d&apos;une candidature de bénévolat. Pour en savoir plus, consultez la&nbsp;
                <a className="span" href="/politique-de-confidentialité">
                  politique de confidentialité
                </a>
                .
              </label>
              {errors.checkbox && (
                <p className="error-message">Merci d&apos;accepter les conditions de politique de confidentialité</p>
              )}
            </div>

            <div className="form-error-message">
              <p style={{ whiteSpace: 'pre-wrap' }}>{confirmationMessage}</p>
            </div>

            <Button type="submit" text="Envoyer" classNameButton="form-button button bg-sky-blue white-text" />
          </form>
        )}
      </section>
    </div>
  );
};

export default Event;
