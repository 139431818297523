import { motion } from 'framer-motion';

const MemberModal = ({
  text,
  classNameText,
  setIsModalOpen,
  src,
  alt,
  classNameImg,
  nameMember,
  iconSrc,
  iconClassName,
}) => (
  <motion.div
    className="modal"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1, transition: { duration: 0.3 } }}
    exit={{ opacity: 0, transition: { duration: 3 } }}
    onClick={() => setIsModalOpen(false)}
  >
    <button className="modal-close" type="button" onClick={() => setIsModalOpen(false)}>
      X <span className="span-close">fermer</span>
    </button>
    <div className="modal-content">
      <img className={classNameImg} src={src} alt={alt} />
      <div className="modal-text">
        <h3 className="member-name bold">{nameMember}</h3>
        <p className={classNameText} style={{ whiteSpace: 'pre-wrap' }}>
          {text}
        </p>
        <img className={iconClassName} src={iconSrc} alt="icône coeur" />
      </div>
    </div>
  </motion.div>
);

export default MemberModal;
