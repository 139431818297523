import { NavLink, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import WhiteLogo from 'images/logo/logo-bis-blanc.svg';
import FacebookWhite from 'images/icons/facebook-blanc.svg';

const Footer = () => {
  let year = new Date();
  const { pathname } = useLocation();

  const toggleHomeLink = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setMenuVisible(false);
  };

  const scrollWithOffsetTo10 = e => {
    const yCoordinate = e.getBoundingClientRect().top + window.scrollY;
    const yOffset = 10;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const scrollWithOffsetTo50 = e => {
    const yCoordinate = e.getBoundingClientRect().top + window.scrollY;
    const yOffset = -50;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  return (
    <div className={pathname === '/' ? 'footer-container' : 'footer-container footer-container-no-margin-bottom'}>
      <div className="logo-fb-container">
        <img src={WhiteLogo} alt="Logo La Main sur le Coeur" height={70} />
        <a
          className="footer-fb-link"
          href="https://www.facebook.com/profile.php?id=100068905187233"
          target="_blank"
          rel="noreferrer"
        >
          {' '}
          Suivez-nous sur
          <img className="fb-footer" src={FacebookWhite} alt="Logo Facebook" height={20} />
        </a>
      </div>

      <div className="legale-container">
        <p className="asso-name">Association La Main sur le Coeur</p>
        <p className="copyright">Tous droits réservés &copy; {year.getFullYear()}</p>
        <br />
        <a href="/politique-de-confidentialité">
          <p className="privacy">Politique de confidentialité</p>
        </a>
        <a href="/mentions-légales">
          <p className="legales-mentions">Mentions légales</p>
        </a>
      </div>

      <div className="footer-nav-container">
        <h3 className="nav-title">Navigation</h3>
        <ul className="footer-navigation-items">
          <li className="item">
            <NavLink smooth="true" to="/" onClick={() => toggleHomeLink()}>
              Accueil
            </NavLink>
          </li>

          <li className="item">
            <HashLink smooth="true" to="/#nos-actions" scroll={el => scrollWithOffsetTo10(el)}>
              Nos actions
            </HashLink>
          </li>

          <li className="item">
            <HashLink smooth="true" to="/#nous-soutenir">
              Nous soutenir
            </HashLink>
          </li>

          <li className="item">
            <HashLink smooth="true" to="/#dernières-actualités">
              Actualités
            </HashLink>
          </li>

          <li className="item">
            <HashLink smooth="true" to="/#qui-sommes-nous" scroll={el => scrollWithOffsetTo50(el)}>
              L&apos;association
            </HashLink>
          </li>
        </ul>
      </div>

      <div className="creation-container">
        <h3 className="creation-title">Création du site internet :</h3>
        <a className="credit gaelle" href="https://gaellebrunel-webdesigner.com/" target="_blank" rel="noreferrer">
          Design et maquette par Gaëlle.B WebDesigner
        </a>
        <a
          className="credit fidia"
          href="https://www.linkedin.com/in/fidiaelbouanani/"
          target="_blank"
          rel="noreferrer"
        >
          Intégration et développement par Fidia
        </a>
      </div>
    </div>
  );
};

export default Footer;
