const BlockButton = ({ classNameButton, classNameIcon, icon, height, text, text2, type }) => {
  return (
    <button type={type} className={classNameButton}>
      <img src={icon} alt="icon" height={height} className={classNameIcon} />
      <h3 className="bold">{text}</h3>
      <h4 className="subtitle">{text2}</h4>
    </button>
  );
};

export default BlockButton;
