import { useState, useEffect, useRef } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useForm } from 'react-hook-form';
import * as emailjs from '@emailjs/browser';
import { init } from '@emailjs/browser';
import dotenv from 'dotenv';

import BlockButton from 'components/BlockButton';
import Button from 'components/Button';
import HeroImg from 'images/pictures/desktop/parrainer.jpg';
import HeroImgMobile from 'images/pictures/mobile/parrainer.jpg';
import SponsorImg from 'images/icons/je-parraine.svg';

const Sponsor = () => {
  const widthScreen = window.innerWidth;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  const lastname = 'nom';
  const firstname = 'prénom';
  const email = 'email';
  const phone = 'téléphone';

  const [isChecked, setIsChecked] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');

  dotenv.config();
  init(process.env.REACT_APP_API_KEY);
  const form = useRef();

  const sendEmail = (formData, e) => {
    e.preventDefault();

    emailjs
      .sendForm(process.env.REACT_APP_SERVICE, process.env.REACT_APP_TPL, form.current, process.env.REACT_APP_API_KEY)
      .then(
        () => {
          // console.log('YEP !', response.status, response.text);
          setIsSubmitted(true);
        },
        () => {
          // console.log('NOPE ...', error);
          setIsSubmitted(false);
          setConfirmationMessage("Oups, il semblerait qu'il y ait un problème ... \n \n Merci de réessayer plus tard.");
        },
      );

    reset();
  };

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    document.title = 'Parrainer un enfant - La Main sur le Coeur';

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className="sponsor-container">
      <section className="sponsor-hero-container">
        <img
          className="hero-img"
          src={widthScreen < 559 ? HeroImgMobile : HeroImg}
          alt="Carton rempli de denrées alimentaires"
        />
        <div className="title-container">
          <BlockButton
            classNameButton="hero-title bg-blue white-text"
            classNameIcon="block-button-icon"
            icon={SponsorImg}
            text="Je m'engage"
            text2={widthScreen < 899 ? 'Je parraine un enfant' : '- Je parraine un enfant'}
            height={50}
          />
        </div>
        <div className="sponsor-text">
          <p className="subtitle">
            Parrainer un enfant c&apos;est l&apos;aider pour sa scolarité, ou pour accéder aux besoins essentiels de la
            vie quotidienne. À petit, moyen ou long terme, et selon ses moyens. <br /> <br /> Pour vous engager auprès
            d&apos;un enfant et le parrainer, par la biais de l&apos;association{' '}
            <span className="span">La Main sur le Cœur</span>, remplissez le formulaire ci-dessous pour que nous
            prenions contact avec vous.
          </p>
        </div>
      </section>

      <section className="form-container bg-light-blue">
        {isSubmitted ? (
          <div className="confirmation-message-container">
            <p className="message-bold">
              Votre demande pour parrainer un enfant a bien été envoyée. <br /> Nous vous remercions pour votre envie de
              vous engager avec nous.
            </p>
            <p className="message">
              Vous allez être redirigé(e) sur la page d&apos;accueil dans quelques secondes. <br /> Si ce n&apos;est pas
              le cas,{' '}
              <a className="home-link" href="/">
                cliquez-ici
              </a>
              .
            </p>
          </div>
        ) : (
          <form className="form" onSubmit={handleSubmit(sendEmail)} ref={form}>
            <input
              type="hidden"
              name="reason"
              {...register('reason', {
                value: 'Le contact souhaite parrainer un enfant !',
              })}
            />

            <ResponsiveMasonry columnsCountBreakPoints={{ 320: 1, 899: 2 }}>
              <Masonry gutter="2rem" className="grid">
                <div className="input-container">
                  <input type="text" placeholder="Nom*" {...register('lastname', { required: true })} />
                  {errors.lastname && <p className="error-message">Merci de renseigner votre {lastname}</p>}
                </div>

                <div className="input-container">
                  <input type="text" placeholder="Prénom*" {...register('firstname', { required: true })} />
                  {errors.firstname && <p className="error-message">Merci de renseigner votre {firstname}</p>}
                </div>

                <div className="input-container">
                  <input type="email" placeholder="E-mail*" {...register('email', { required: true })} />
                  {errors.email && <p className="error-message">Merci de renseigner votre {email}</p>}
                </div>

                <div className="input-container">
                  <input type="text" placeholder="Téléphone*" {...register('phone', { required: true })} />
                  {errors.phone && <p className="error-message">Merci de renseigner votre {phone}</p>}
                </div>
              </Masonry>
            </ResponsiveMasonry>

            <p className="form-mentions">* Champs obligatoires</p>

            <div className="form-checkbox">
              <input
                className="checkbox"
                type="checkbox"
                id="checkbox"
                value={isChecked}
                checked={isChecked}
                {...register('checkbox', {
                  required: true,
                  onChange: () => {
                    handleOnChange();
                  },
                })}
              />
              <label className="checkbox-text" htmlFor="checkbox">
                En cochant cette case, j&apos;accepte que La Main sur le Cœur traite mes données dans le cadre de
                l&apos;envoi d&apos;une candidature de bénévolat. Pour en savoir plus, consultez la&nbsp;
                <a className="span" href="/politique-de-confidentialité">
                  politique de confidentialité
                </a>
                .
              </label>
              {errors.checkbox && (
                <p className="error-message">Merci d&apos;accepter les conditions de politique de confidentialité</p>
              )}
            </div>

            <div className="form-error-message">
              <p style={{ whiteSpace: 'pre-wrap' }}>{confirmationMessage}</p>
            </div>

            <Button type="submit" text="Envoyer" classNameButton="form-button button bg-blue white-text" />
          </form>
        )}
      </section>
    </div>
  );
};

export default Sponsor;
