import { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import HeartRed from 'images/icons/faire-un-don-mini.svg';
import HeartWhite from 'images/icons/faire-un-don-coeur-blanc.svg';
import Logo from 'images/logo/logo.svg';
import LogoTiny from 'images/logo/logo-bis.svg';
import HomeIconWhite from 'images/icons/accueil-blanc.svg';
import HomeIconBlue from 'images/icons/accueil-bleu.svg';
import Give from 'images/icons/je-donne-blanc.svg';

import Button from 'components/Button';
import BurgerButton from 'components/BurgerButton';
import BlockButton from 'components/BlockButton';

const Header = () => {
  const [fixed, setFixed] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);

  const widthScreen = window.innerWidth;
  const heightScreen = window.innerHeight;

  // revenir en haut de page et fermer le menu
  const toggleHomeLink = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setMenuVisible(false);
  };

  // fixer le menu quand le scroll est > à 100vh
  const toggleVisible = () => {
    const scrollPosition = window.scrollY;
    const isScrolledPastTheScreen = scrollPosition > heightScreen;
    setFixed(isScrolledPastTheScreen);
  };

  // bloquer et réactiver le scroll à l'ouverture et fermeture du menu mobile
  if (menuVisible) {
    document.body.style.overflowY = 'hidden';
  } else {
    document.body.style.overflowY = 'unset';
  }

  // gérer le positionnement de la navigation sur les hashlink
  const scrollWithOffsetTo10 = e => {
    const yCoordinate = e.getBoundingClientRect().top + window.scrollY;
    const yOffset = 10;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const scrollWithOffsetTo50 = e => {
    const yCoordinate = e.getBoundingClientRect().top + window.scrollY;
    const yOffset = -50;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <header className={fixed ? 'header header-fixed' : 'header'}>
      <div className="header-container">
        <Link className="logo-container" to="/" onClick={() => toggleHomeLink()}>
          <img className="logo" src={fixed ? LogoTiny : Logo} alt="logo" height={fixed ? 50 : 175} />
        </Link>

        <nav className="navigation-container">
          <NavLink to="/" onClick={() => toggleHomeLink()}>
            <img className="nav-icon" src={fixed ? HomeIconBlue : HomeIconWhite} alt="home" height={30} />
          </NavLink>

          <div className="navigation">
            <ul className={menuVisible ? 'navigation-items-mobile navigation-items-mobile-fixed' : 'navigation-items'}>
              <li className="nav-item">
                <HashLink
                  smooth="true"
                  to="/#nos-actions"
                  onClick={() => {
                    setMenuVisible(false);
                  }}
                  scroll={el => scrollWithOffsetTo10(el)}
                >
                  Nos actions
                </HashLink>
              </li>

              <li className="nav-item">
                <HashLink smooth="true" to="/#nous-soutenir" onClick={() => setMenuVisible(false)}>
                  Nous soutenir
                </HashLink>
              </li>

              <li className="nav-item">
                <HashLink
                  smooth="true"
                  to="/#dernières-actualités"
                  onClick={() => setMenuVisible(false)}
                  scroll={el => scrollWithOffsetTo50(el)}
                >
                  Actualités
                </HashLink>
              </li>

              <li className="nav-item">
                <HashLink
                  smooth="true"
                  to="/#qui-sommes-nous"
                  onClick={() => setMenuVisible(false)}
                  scroll={el => scrollWithOffsetTo50(el)}
                >
                  L&apos;association
                </HashLink>
              </li>

              <Link
                className={menuVisible ? 'menu-block-button-ctn bg-red' : 'invisible-menu-block-button-ctn'}
                to="/faire-un-don"
                onClick={() => setMenuVisible(false)}
              >
                <BlockButton
                  classNameButton="block-button bg-red block-button-give"
                  classNameIcon="block-button-icon"
                  icon={Give}
                  text="Je donne"
                  height={35}
                />
              </Link>
            </ul>
          </div>

          <button className="burger-container" onClick={() => setMenuVisible(!menuVisible)}>
            {!menuVisible && !fixed && <BurgerButton classNameBurger="line" />}
            {!menuVisible && fixed && <BurgerButton classNameBurger="line-fixed" />}
            {menuVisible && <div className="close-cross">X</div>}
          </button>
        </nav>

        {fixed ? (
          <Link className="header-button-ctn" to="/faire-un-don">
            <button
              className="button header-button-fixed"
              onMouseEnter={e => {
                e.target.childNodes[0].src = HeartRed;
              }}
              onMouseLeave={e => (e.target.childNodes[0].src = HeartWhite)}
            >
              <img className="icon" src={HeartWhite} alt="icon" height={16} />
              <p className="button-text">Faire un don</p>
            </button>
          </Link>
        ) : (
          <Link className="header-button-ctn" to="/faire-un-don">
            <Button
              text="Faire un don"
              classNameButton="button header-button"
              classNameIcon="icon-button"
              icon={fixed ? HeartWhite : HeartRed}
              height={16}
            />
          </Link>
        )}
      </div>
    </header>
  );
};

export default Header;
