import { Link } from 'react-router-dom';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import Button from 'components/Button';
import HeartRed from 'images/icons/faire-un-don-mini.svg';

const HeroCarousel = () => {
  return (
    <Splide className="hero-carousel">
      <SplideSlide className="carousel-element-first">
        <div className="title-container">
          <div className="hero-text">
            <h2 className="title">Des actions humanitaires</h2>
            <h3 className="subtitle">
              Pour les <span className="subtitle-span">enfants défavorisés</span>
            </h3>
            <Link to="/faire-un-don">
              <Button
                text="Faire un don"
                classNameButton="button title-button"
                classNameIcon="icon-title-button"
                icon={HeartRed}
                height={16}
              />
            </Link>
          </div>
        </div>
      </SplideSlide>

      <SplideSlide className="carousel-element-second">
        <div className="title-container">
          <div className="hero-text">
            <h2 className="title">Soins et Scolarisation</h2>
            <h3 className="subtitle">
              Pour les <span className="subtitle-span">orphelins</span> et{' '}
              <span className="subtitle-span">enfants de rue</span>
            </h3>
            <Link to="/faire-un-don">
              <Button
                text="Faire un don"
                classNameButton="button title-button"
                classNameIcon="icon-title-button"
                icon={HeartRed}
                height={16}
              />
            </Link>
          </div>
        </div>
      </SplideSlide>
    </Splide>
  );
};

export default HeroCarousel;
