import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as emailjs from '@emailjs/browser';
import { init } from '@emailjs/browser';
import dotenv from 'dotenv';
import axios from 'axios';

import BlockButton from 'components/BlockButton';
import Button from 'components/Button';
import ActionContainer from 'components/ActionContainer';
import AssociationCarousel from 'components/AssociationCarousel';
import HeroCarousel from 'components/HeroCarousel';

import Hands from 'images/icons/actions-humanitaires.svg';
import World from 'images/icons/actions-de-solidarite-internationale.svg';
import Help from 'images/icons/aides-humanitaires.svg';
import Hearts from 'images/icons/nous-soutenir.svg';
import GiveRed from 'images/icons/je-donne-rouge.svg';
import BlueHands from 'images/icons/parrainer-un-enfant.svg';
import YellowEngagement from 'images/icons/s-engager.svg';
import Participate from 'images/icons/y-participer.svg';
import Agenda from 'images/icons/evenement-bleu.svg';
import Newsletter from 'images/icons/newsletter.svg';
import Association from 'images/icons/l-association.svg';
import FacebookBlue from 'images/icons/facebook-bleu.svg';
import FB1 from 'images/FB/FB1.png';
import FB2 from 'images/FB/FB2.png';
import FB3 from 'images/FB/FB3.png';
import FB4 from 'images/FB/FB4.png';

const Home = () => {
  const widthScreen = window.innerWidth;

  const [isChecked, setIsChecked] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState([]);

  const email = 'email';

  dotenv.config();
  init(process.env.REACT_APP_API_KEY);
  const form = useRef();

  const sendEmail = (formData, e) => {
    e.preventDefault();

    emailjs
      .sendForm(process.env.REACT_APP_SERVICE, process.env.REACT_APP_TPL, form.current, process.env.REACT_APP_API_KEY)
      .then(
        () => {
          // console.log('YEP !', response.status, response.text);
          setIsSubmitted(true);
        },
        () => {
          // console.log('NOPE ...', error);
          setIsSubmitted(false);
          setConfirmationMessage("Oups, il semblerait qu'il y ait un problème ... \n \n Merci de réessayer plus tard.");
        },
      );

    reset();
  };

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}evenement?_embed`)
      .then(response => {
        // console.log('reponse :', response.data);
        setIsLoading(false);
        setEvents(response.data);
      })
      .catch(error => {
        // console.error('error:', error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    document.title = 'La Main sur le Coeur';

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className="home-container">
      {/* //& --------------------- HERO --------------------- */}
      <section className="hero-container" id="accueil">
        <div className="hero-content">
          <HeroCarousel />
        </div>
        <ActionContainer />
      </section>

      {/* //& ------------------- MISSIONS ------------------- */}
      <section className="missions-container" id="nos-actions">
        <h2 className="title-missions bold-outfit">
          Nos <span className="yellow-text">missions</span> et <span className="blue-text">actions</span>
        </h2>
        <h3 className="subtitle-missions">
          L&apos;Association <span className="bold-outfit">&quot;La main sur le cœur&quot;</span> a pour objet :
        </h3>

        <div className="missions-content">
          <div className="missions-content-item">
            <div className="missions-hero">
              <div className="img-container bg-light-blue">
                <img className="missions-img" src={Hands} alt="" height={widthScreen < 599 ? 50 : 70} />
              </div>
              <h3 className="missions-subtitle blue-text bold-outfit">
                Actions <br /> humanitaires
              </h3>
            </div>
            <p className="missions-text">
              Dans le domaine de <span className="bold-poppins">l&apos;éducation</span> et de la{' '}
              <span className="bold-poppins">santé</span> des <span className="bold-poppins">enfants défavorisés</span>.
            </p>
          </div>

          <div className="missions-content-item">
            <div className="missions-hero missions-hero-world">
              <div className="img-container bg-light-red">
                <img className="missions-img" src={World} alt="" height={widthScreen < 599 ? 50 : 70} />
              </div>
              <h3 className="missions-subtitle red-text bold-outfit">Actions de solidarité internationale</h3>
            </div>
            <p className="missions-text">
              Pour la prise en compte de la <span className="bold-poppins">scolarisation</span> et des{' '}
              <span className="bold-poppins">soins médicaux </span>
              des <span className="bold-poppins">enfants défavorisés</span> (orphelins, enfants de rue...).
            </p>
          </div>

          <div className="missions-content-item">
            <div className="missions-hero">
              <div className="img-container bg-yellow-light">
                <img className="missions-img" src={Help} alt="" height={widthScreen < 599 ? 50 : 70} />
              </div>
              <h3 className="missions-subtitle yellow-text bold-outfit">
                Aides <br /> humanitaires
              </h3>
            </div>

            <p className="missions-text">
              Dans la <span className="bold-poppins">distribution de kits alimentaires</span>,{' '}
              <span className="bold-poppins">scolarité</span> et <span className="bold-poppins">vestimentaire</span> au
              profit des <span className="bold-poppins">enfants défavorisés</span> socialement à travers la planète sans
              distinction de race.
            </p>
          </div>
        </div>
      </section>

      {/* //& -------------------- SOUTIEN ------------------- */}
      <section className="support-container" id="nous-soutenir">
        <div className="support-hero bg-light-pink">
          <div className="support-title-container">
            <img className="support-title-img" src={Hearts} alt="hearts" />
            <h2 className="support-title red-text">Nous soutenir</h2>
          </div>
          <p className="support-text">
            Il y a plusieurs manières de <span className="bold-poppins">venir en aide </span>
            aux <span className="bold-poppins">enfants défavorisés </span> à travers notre association :
          </p>
        </div>

        <div className="support-content-container">
          <Link className="support-content" to="/parrainer-un-enfant">
            <div className="support-block">
              <div className="img-container">
                <div className="icon-container">
                  <img className="icon" src={BlueHands} alt="hands-icon" />
                </div>
                <div className="block-button-text-img">
                  <p className="span-btn bold-outfit">Je deviens parrain ou marraine d&apos;un enfant</p>
                  <p>Le parrainage est un don régulier en faveur d&apos;un enfant</p>
                </div>
              </div>

              <BlockButton
                classNameButton="block-button support-button bg-blue"
                classNameIcon="support-icon"
                text="Parrainer un enfant"
                height={60}
              />
            </div>
          </Link>

          <Link className="support-content" to="/devenir-bénévole">
            <div className="support-block">
              <div className="img-container">
                <div className="icon-container">
                  <img className="icon" src={YellowEngagement} alt="hand-heart-icon" />
                </div>
                <div className="block-button-text-img">
                  <p className="span-btn bold-outfit">Je m&apos;engage pour l&apos;association</p>
                  <p>Devenir bénévole, participer au financement d&apos;un projet</p>
                </div>
              </div>

              <BlockButton
                classNameButton="block-button support-button bg-yellow"
                classNameIcon="support-icon"
                text="S'engager"
                height={60}
              />
            </div>
          </Link>

          <Link className="support-content" to="/faire-un-don">
            <div className="support-block">
              <div className="img-container">
                <div className="icon-container">
                  <img className="icon" src={GiveRed} alt="give-money-icon" />
                </div>
                <div className="block-button-text-img">
                  <p className="span-btn bold-outfit">Je donne une fois ou tous les mois</p>
                  <p>Nous comptons sur la générosité des bienfaiteurs pour accomplir de beaux projets</p>
                </div>
              </div>

              <BlockButton
                classNameButton="block-button support-button bg-red"
                classNameIcon="support-icon"
                text="Faire un don"
                height={60}
              />
            </div>
          </Link>
        </div>

        <div></div>
      </section>

      {/* //& -------------------- EVENTS -------------------- */}
      <section className="events-container" id="dernières-actualités">
        <div className="white-ctn">
          <img className="agenda-img" src={Agenda} alt="agenda" height={widthScreen < 599 ? 80 : 140} />
          <div className="events-hero">
            <h2 className="events-title blue-text">L&apos;événement à venir</h2>
            <p className="events-subtitle cian-text bold">et à ne pas manquer</p>
          </div>
        </div>

        <div className="blue-ctn bg-sky-blue">
          {isLoading && <p className="loader">Chargement des événements ...</p>}

          {events &&
            events.length > 0 &&
            events[0].acf.date !== '' &&
            events[0].title.rendered !== '' &&
            events[0].content.rendered !== '' && (
              <div className="event-ctn">
                <h3 className="events-date">
                  <span className="span-date bold-poppins">{events[0].acf.date}</span> - {events[0].title.rendered}
                </h3>
                <p
                  className="events-text"
                  dangerouslySetInnerHTML={{
                    __html: events[0].content.rendered,
                  }}
                />
                <Link to="/participer-au-prochain-événement">
                  <Button
                    text="Y participer"
                    classNameButton="events-button blue-text"
                    classNameIcon="icon"
                    icon={Participate}
                    alt="icône flèche"
                    height={16}
                  />
                </Link>
              </div>
            )}

          {(events.length === 0 ||
            events[0].acf.date === '' ||
            events[0].title.rendered === '' ||
            events[0].content.rendered === '') && (
            <div className="event-ctn">
              <h3 className="events-date">
                <span className="span-date bold-poppins">Date et destination</span> - à venir
              </h3>
              <p className="events-text">
                L&apos;organisation du prochain voyage est en cours. Vous pourrez très bientôt avoir toutes les
                informations le concernant, et accéder au formulaire d&apos;inscription pour y participer.
              </p>
              <Button
                text="Inscriptions bientôt ouvertes"
                classNameButton="events-button blue-text"
                classNameIcon="icon not-now-icon"
              />
            </div>
          )}
        </div>
      </section>

      {/* //& --------------------- ACTU --------------------- */}
      <div className="actu-container">
        <div className="actu-hero">
          <div className="actu-title-container">
            <h2 className="actu-title black-text">
              Nos dernières <span className="blue-text">actualités</span>
            </h2>
          </div>
          <p className="actu-text">sur Facebook</p>
        </div>

        <div className="images-container">
          <img src={FB1} alt="derniers posts Facebook" />
          <img src={FB2} alt="derniers posts Facebook" />
          <img src={FB3} alt="derniers posts Facebook" />
          <img src={FB4} alt="derniers posts Facebook" />
        </div>

        <a href="https://www.facebook.com/profile.php?id=100068905187233" target="_blank" rel="noreferrer">
          <Button
            text="Nous suivre sur Facebook"
            classNameButton="actu-button white-text"
            classNameIcon="icon"
            icon={FacebookBlue}
            alt="icon Facebook"
            height={25}
          />
        </a>
      </div>

      {/* //& ------------------ NEWSLETTER ------------------ */}
      <section className="newsletter-container" id="dernières-actualités">
        <div className="yellow-ctn bg-yellow-light">
          <h3 className="newsletter-title yellow-text">Inscrivez-vous à notre Newsletter</h3>

          <p className="newsletter-text">
            Pour connaître les informations sur la vie de notre association, les actions et événements en cours et
            recevoir les dernières actualités, inscrivez-vous à notre newsletter.
          </p>

          {isSubmitted ? (
            <div className="confirmation-message-container">
              <p className="message">
                Votre inscription à notre Newsletter a bien été prise en compte. <br /> Vous recevrez à partir
                d&apos;aujourd&apos;hui, toutes nos Newsletter
              </p>

              <p className="unsubscribtion-message">Pour vous désabonner, merci de nous écrire directement.</p>
            </div>
          ) : (
            <>
              <form className="newsletter-form" action="submit" onSubmit={handleSubmit(sendEmail)} ref={form}>
                <input
                  type="hidden"
                  name="reason"
                  {...register('reason', {
                    value: 'Le contact souhaite recevoir la Newsletter !',
                  })}
                />
                <div className="form-container">
                  <input type="email" placeholder="Votre adresse e-mail" {...register('email', { required: true })} />
                  <button className="newsletter-button bg-yellow">Envoyer</button>
                </div>
                {errors.email && <p className="error-message">Merci de renseigner votre {email}</p>}
              </form>
              <div className="form-checkbox">
                <input
                  className="checkbox"
                  type="checkbox"
                  id="checkbox"
                  value={isChecked}
                  checked={isChecked}
                  {...register('checkbox', {
                    required: true,
                    onChange: () => {
                      handleOnChange();
                    },
                  })}
                />
                <label className="checkbox-text" htmlFor="checkbox">
                  En cochant cette case, j&apos;accepte que La Main sur le Cœur traite mes données dans le cadre de
                  l&apos;envoi d&apos;une candidature de bénévolat. Pour en savoir plus, consultez la&nbsp;
                  <a className="span" href="/politique-de-confidentialité">
                    politique de confidentialité
                  </a>
                  .
                </label>
                {errors.checkbox && (
                  <p className="error-message">Merci d&apos;accepter les conditions de politique de confidentialité</p>
                )}
              </div>
            </>
          )}
        </div>

        <div className="white-ctn">
          <img
            className="newsletter-img"
            src={Newsletter}
            alt="icône de newsletter"
            height={widthScreen < 599 ? 80 : 140}
          />
          <div className="newsletter-hero">
            <h2 className="newsletter-title yellow-text">Suivre nos actions</h2>
          </div>
        </div>
      </section>

      {/* //& ------------------ ASSOCIATION ------------------ */}
      <section className="asso-container" id="qui-sommes-nous">
        <div className="asso-title-container">
          <h2 className="asso-title blue-text">L&apos;association</h2>
          <img className="asso-title-img" src={Association} alt="hearts" />
        </div>
        <AssociationCarousel />
      </section>
      <ActionContainer />
    </div>
  );
};

export default Home;
