import { useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import Button from 'components/Button';

import Magnama from 'images/pictures/desktop/magnama.jpg';
import Fatou from 'images/pictures/desktop/fatou.jpg';
import Marietou from 'images/pictures/desktop/marietou.jpg';
import Waris from 'images/pictures/desktop/waris.jpg';
import Josiane from 'images/pictures/desktop/josiane.jpg';
import ReadMore from 'images/icons/lire-la-suite.svg';
import Heart from 'images/icons/heart.svg';
import MemberModal from 'components/MemberModal';

const members = [
  {
    id: 1,
    name: 'Magnama Bamba',
    src: Magnama,
    text: "Maman de quatres enfants, vivant en France et Infirmière d'état. \nJ'ai eu à cœur la création de cette association lors de mes nombreux voyages en Afrique ...",
    btnClassName: 'magnama-carousel-button button carousel-button',
  },
  {
    id: 2,
    name: 'Fatou Tatus Fofana',
    src: Fatou,
    text: "L'association la main sur le cœur vous présente la marraine et la représentante en côte d'ivoire madame Fatou Tatus Fofana grande dame, commerçante, femme digne aimante. La main sur le cœur est ...",
    btnClassName: 'fatou-carousel-button button carousel-button',
  },
  {
    id: 3,
    name: 'Marietou Camara',
    src: Marietou,
    text: "Je suis Marietou Camara. Je suis titulaire d'une Maîtrise en Droit et d'un Master en Gestion des Ressouces Humaines. Je suis actuellement à mon propre compte. Je fais la transformation du Karité en produits cosmétiques.",
    btnClassName: 'marietou-carousel-button button carousel-button',
  },
  {
    id: 4,
    name: 'Waris',
    src: Waris,
    text: 'Je suis Waris. Je suis chargée de communication et de management.',
    btnClassName: 'waris-carousel-button button carousel-button',
  },
  {
    id: 5,
    name: 'Josiane',
    src: Josiane,
    text: "Je suis Josiane et je suis présidente d'honneur.",
    btnClassName: 'josiane-carousel-button button carousel-button',
  },
];

const moreTextMagnama =
  "Maman de quatre enfants, vivant en France et Infirmière d'état. J'ai eu à cœur la création de cette association lors de mes nombreux voyages en Afrique. \n Je suis particulièrement touchée par le manque des enfants surtout les orphelins. \n \nJe me dis qu'ayant eu une enfance épanouie en France et voir tant d'enfants ne mangeant pas à leur faim, déscolarisés par manque de finance, lié souvent à la disparition d'un parent voir les deux parents, de plus les soins médicaux étant vraiment des options pour eux. L'idée m'est venue en créant cette association. \n \nNous pourrions apporter notre aide même minime, avec le soutien de quelaues bienfaiteurs. Nous posons des actions annuelles et des actions spontanées selon le degré d'urgence de la demande. Nous nous mettons aussi en contact directement avec des structures d'accueils des orphelins en leurs donnant des denrées alimentaires, du matériel nécessaire au confort des enfants. \nNous prenons en charge également la scolarité et les repas (petit-déjeuner, déjeuner) de quelques enfants n'ayant aucun moyen pour y subvenir. \nNous comptons sur la générosité des bienfaiteurs pour accomplir des beaux projets et redonner un petit sourire et un espoir à un enfant. \n \nPrésidente fondatrice de l'association la main sur le cœur";

const moreTextFatou =
  "L'association la main sur le cœur vous présente la marraine et la représentante en côte d'ivoire madame Fatou Tatus Fofana grande dame, commerçante, femme digne aimante. \n \nLa main sur le cœur est heureux de compter sur son dévouement auprès des orphelins.";

const AssociationCarousel = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const widthScreen = window.innerWidth;

  const handleClick = id => {
    if (!!isModalOpen !== false) {
      setIsModalOpen(false);
      return;
    }

    setIsModalOpen(id);
  };

  return (
    <>
      <div className="association-carousel">
        <Splide
          options={{
            perPage: widthScreen < 899 ? 1 : 2,
            perMove: 1,
          }}
        >
          {members.map(member => (
            <SplideSlide key={member.id}>
              <div className="carousel-element">
                <div className="element-ctn">
                  <div className="image-container">
                    <img className="image-member" src={member.src} alt={member.name} />
                  </div>
                  <div className="presentation-container">
                    <h3 className="name">{member.name}</h3>
                    <p className="presentation" style={{ whiteSpace: 'pre-wrap' }}>
                      {member.text}
                    </p>
                    <Button
                      text="Lire la suite"
                      classNameButton={member.btnClassName}
                      onClick={() => handleClick(member.id)}
                      classNameIcon="icon-title-button"
                      icon={ReadMore}
                      height={16}
                    />
                  </div>
                </div>
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </div>

      {isModalOpen === 1 && (
        <MemberModal
          classNameText="moretext"
          classNameImg="image-member"
          setIsModalOpen={setIsModalOpen}
          src={Magnama}
          text={moreTextMagnama}
          alt="Magnama Bamba"
          nameMember="Magnama Bamba"
          iconSrc={Heart}
          iconClassName="heartIconMagnama"
        />
      )}
      {isModalOpen === 2 && (
        <MemberModal
          classNameText="moretext"
          setIsModalOpen={setIsModalOpen}
          src={Fatou}
          text={moreTextFatou}
          alt="Fatou Tatus Fofana"
          classNameImg="image-member"
          nameMember="Fatou Tatus Fofana"
          iconClassName="heartIconFatou"
        />
      )}
    </>
  );
};

export default AssociationCarousel;
