import { useState } from 'react';
import { Link } from 'react-router-dom';

import BlockButton from 'components/BlockButton';
import Sponsor from 'images/icons/je-parraine.svg';
import Engagement from 'images/icons/je-m-engage.svg';
import Give from 'images/icons/je-donne-blanc.svg';

const ActionContainer = () => {
  const [fixed, setFixed] = useState(false);
  const heightScreen = window.innerHeight;
  const widthScreen = window.innerWidth;

  const toggleVisible = () => {
    const scrolled = window.scrollY;
    if (scrolled > heightScreen) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <div
      className={widthScreen < 1199 && fixed ? 'action-container-mobile' : 'action-container'}
      style={{
        position: fixed ? 'fixed' : '',
        bottom: fixed ? '0' : '',
      }}
    >
      <Link className="block-button-ctn bg-blue" to="/parrainer-un-enfant">
        <BlockButton
          classNameButton="block-button bg-blue block-button-sponsor"
          classNameIcon="block-button-icon"
          icon={Sponsor}
          text="Je parraine"
          height={50}
        />
        <div className="block-button-text">
          <p className="span-btn bold-outfit">Je deviens parrain ou marraine d&apos;un enfant</p>
          <p>Le parrainage est un don régulier en faveur d&apos;un enfant</p>
        </div>
      </Link>

      <Link className="block-button-ctn bg-yellow" to="/devenir-bénévole">
        <BlockButton
          classNameButton="block-button bg-yellow block-button-committment"
          classNameIcon="block-button-icon"
          icon={Engagement}
          text="Je m'engage"
          height={50}
        />
        <div className="block-button-text">
          <p className="span-btn bold-outfit">Je m&apos;engage pour l&apos;association</p>
          <p>Devenir bénévole, participer au financement d&apos;un projet</p>
        </div>
      </Link>

      <Link className="block-button-ctn bg-red" to="/faire-un-don">
        <BlockButton
          classNameButton="block-button bg-red block-button-give"
          classNameIcon="block-button-icon"
          icon={Give}
          text="Je donne"
          height={widthScreen < 1199 ? 35 : 50}
        />
        <div className="block-button-text block-button-text-give">
          <p className="span-btn bold-outfit">Je donne une fois ou tous les mois</p>
          <p>Nous comptons sur la générosité des bienfaiteurs pour accomplir de beaux projets</p>
        </div>
      </Link>
    </div>
  );
};

export default ActionContainer;
