import { useState, useEffect, useRef } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useForm } from 'react-hook-form';
import * as emailjs from '@emailjs/browser';
import { init } from '@emailjs/browser';
import dotenv from 'dotenv';

import BlockButton from 'components/BlockButton';
import Button from 'components/Button';
import HeroImg from 'images/pictures/desktop/s-engager-devenir-benevole.jpg';
import HeroImgMobile from 'images/pictures/desktop/faire-un-don.jpg';
import Engagement from 'images/icons/je-m-engage.svg';

const Volunteer = () => {
  const widthScreen = window.innerWidth;

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      otherSituation: '',
    },
  });

  const lastname = 'nom';
  const firstname = 'prénom';
  const email = 'email';
  const emailConfirm = 'email';
  const phone = 'téléphone';
  const birthyear = 'année de naissance';
  const city = 'ville';
  const postCode = 'code postal';
  const message = 'message';

  const [isChecked, setIsChecked] = useState(false);
  const [situation, setSituation] = useState('');
  const [otherSituation, setOtherSituation] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');

  dotenv.config();
  init(process.env.REACT_APP_API_KEY);
  const form = useRef();

  const sendEmail = (formData, e) => {
    e.preventDefault();

    emailjs
      .sendForm(process.env.REACT_APP_SERVICE, process.env.REACT_APP_TPL, form.current, process.env.REACT_APP_API_KEY)
      .then(
        () => {
          // console.log('YEP !', response.status, response.text);
          setIsSubmitted(true);
        },
        () => {
          // console.log('NOPE ...', error);
          setIsSubmitted(false);
          setConfirmationMessage("Oups, il semblerait qu'il y ait un problème ... \n \n Merci de réessayer plus tard.");
        },
      );

    reset();
  };

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    const subscription = watch(value => {
      setSituation(value.situation);
    });

    setValue('otherSituation', otherSituation);
    trigger('otherSituation');

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    document.title = 'Devenir bénévole - La Main sur le Coeur';

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className="volunteer-container">
      <section className="volunteer-hero-container">
        <img
          className="hero-img"
          src={widthScreen < 559 ? HeroImgMobile : HeroImg}
          alt="Carton rempli de denrées alimentaires"
        />
        <div className="title-container">
          <BlockButton
            classNameButton="hero-title bg-yellow white-text"
            classNameIcon="block-button-icon"
            icon={Engagement}
            text="Je m'engage"
            text2={widthScreen < 899 ? 'Je deviens bénévole' : '- Je deviens bénévole'}
            height={50}
          />
        </div>
        <div className="volunteer-text">
          <p className="subtitle">
            Pour vous engager et devenir bénévole auprès de l&apos;association{' '}
            <span className="span">La Main sur le Cœur</span>, remplissez le formulaire ci-dessous pour nous envoyer
            votre candidature.
          </p>
        </div>
      </section>

      <section className="form-container bg-yellow-light">
        {isSubmitted ? (
          <div className="confirmation-message-container">
            <p className="message-bold">
              Votre candidature pour devenir bénévole a bien été envoyée. <br /> Nous vous remercions pour votre envie
              de vous engager avec nous.
            </p>
            <p className="message">
              Vous allez être redirigé(e) sur la page d&apos;accueil dans quelques secondes. <br /> Si ce n&apos;est pas
              le cas,{' '}
              <a className="home-link" href="/">
                cliquez-ici
              </a>
              .
            </p>
          </div>
        ) : (
          <form className="form" onSubmit={handleSubmit(sendEmail)} ref={form}>
            <input
              type="hidden"
              name="reason"
              {...register('reason', {
                value: 'Le contact devenir bénévole !',
              })}
            />

            <ResponsiveMasonry columnsCountBreakPoints={{ 320: 1, 899: 2 }}>
              <Masonry gutter="2rem" className="grid">
                <div className="input-container">
                  <input type="text" placeholder="Nom*" {...register('lastname', { required: true })} />
                  {errors.lastname && <p className="error-message">Merci de renseigner votre {lastname}</p>}
                </div>

                <div className="input-container">
                  <input type="text" placeholder="Prénom*" {...register('firstname', { required: true })} />
                  {errors.firstname && <p className="error-message">Merci de renseigner votre {firstname}</p>}
                </div>

                <div className="input-container">
                  <input type="email" placeholder="E-mail*" {...register('email', { required: true })} />
                  {errors.email && <p className="error-message">Merci de renseigner votre {email}</p>}
                </div>

                <div className="input-container">
                  <input
                    type="email"
                    placeholder="E-mail (confirmation)*"
                    {...register('emailConfirm', { required: true })}
                  />
                  {errors.emailConfirm && <p className="error-message">Merci de confirmer votre {emailConfirm}</p>}
                </div>

                <div className="input-container">
                  <input type="text" placeholder="Téléphone*" {...register('phone', { required: true })} />
                  {errors.phone && <p className="error-message">Merci de renseigner votre {phone}</p>}
                </div>

                <div className="input-container">
                  <input type="text" placeholder="Année de naissance*" {...register('birthyear', { required: true })} />
                  {errors.birthyear && <p className="error-message">Merci de renseigner votre {birthyear}</p>}
                </div>

                <div className="input-container">
                  <input type="text" placeholder="Ville*" {...register('city', { required: true })} />
                  {errors.city && <p className="error-message">Merci de renseigner votre {city}</p>}
                </div>

                <div className="input-container">
                  <input type="text" placeholder="Code postal*" {...register('postCode', { required: true })} />
                  {errors.postCode && <p className="error-message">Merci de renseigner votre {postCode}</p>}
                </div>
              </Masonry>
            </ResponsiveMasonry>

            <div
              className={
                situation === 'autre :' ? 'large-situation-container situation-container' : 'situation-container'
              }
            >
              <select className="select-situation" name="situation" {...register('situation', { required: true })}>
                <option value="">Situation actuelle*</option>
                <option value="étudiant(e)">Étudiant(e)</option>
                <option value="en activité professionnelle">En activité professionnelle</option>
                <option value="en recherche d'emploi">En recherche d&apos;emploi</option>
                <option value="retraité(e)">Retraité(e)</option>
                <option value="autre :">Autre</option>
              </select>
              {situation === 'autre :' && (
                <div className="other-container">
                  <input
                    type="text"
                    name="otherSituation"
                    placeholder="Quelle est votre situation ?*"
                    {...register('otherSituation')}
                  />
                </div>
              )}
              {errors.situation && <p className="error-message">Merci de renseigner votre {situation}</p>}
            </div>

            <div className="message-container">
              <textarea
                className="textarea"
                type="text"
                placeholder="Expliquez pourquoi, et en quelques lignes, vous souhaitez rejoindre les équipes de La Main sur le Cœur.*"
                {...register('message', { required: true })}
              />
              {errors.message && <p className="error-message">Merci de renseigner votre {message}</p>}
            </div>

            <p className="form-mentions">* Champs obligatoires</p>

            <div className="form-checkbox">
              <input
                className="checkbox"
                type="checkbox"
                id="checkbox"
                value={isChecked}
                checked={isChecked}
                {...register('checkbox', {
                  required: true,
                  onChange: () => {
                    handleOnChange();
                  },
                })}
              />
              <label className="checkbox-text" htmlFor="checkbox">
                En cochant cette case, j&apos;accepte que La Main sur le Cœur traite mes données dans le cadre de
                l&apos;envoi d&apos;une candidature de bénévolat. Pour en savoir plus, consultez la&nbsp;
                <a className="span" href="/politique-de-confidentialité">
                  politique de confidentialité
                </a>
                .
              </label>
              {errors.checkbox && (
                <p className="error-message">Merci d&apos;accepter les conditions de politique de confidentialité</p>
              )}
            </div>

            <div className="form-error-message">
              <p style={{ whiteSpace: 'pre-wrap' }}>{confirmationMessage}</p>
            </div>

            <Button
              type="submit"
              text="Envoyer ma canditature"
              classNameButton="form-button button bg-yellow white-text"
            />
          </form>
        )}
      </section>
    </div>
  );
};

export default Volunteer;
