const Button = ({ text, classNameButton, classNameIcon, icon, height, onMouseEnter, onMouseLeave, onClick, type }) => {
  return (
    <button
      type={type}
      className={classNameButton}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      <div className="icon-container not-now-event-ctn">
        <img src={icon} alt="icon" height={height} className={classNameIcon} />
      </div>
      <p className="button-text">{text}</p>
    </button>
  );
};

export default Button;
