import { useEffect } from 'react';

import BlockButton from 'components/BlockButton';
import HeroImg from 'images/pictures/desktop/participer-a-l-evenement.jpg';
import HeroImgMobile from 'images/pictures/mobile/la-main-sur-le-coeur-participer-a-l-evenement-mobile.jpg';
import GiveIcon from 'images/icons/je-donne-blanc.svg';
import PayPayLogo from 'images/logo/logo-PayPal.png';
import HelloAssoLogo from 'images/logo/logo-HelloAsso.png';

const Give = () => {
  const widthScreen = window.innerWidth;

  useEffect(() => {
    document.title = 'Faire un don - La Main sur le Coeur';

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className="give-container">
      <section className="give-hero-container">
        <img
          className="hero-img"
          src={widthScreen < 559 ? HeroImgMobile : HeroImg}
          alt="Bénévole portant le t-shirt de l'association"
        />
        <div className="title-container">
          <BlockButton
            classNameButton="hero-title bg-red white-text"
            classNameIcon="block-button-icon"
            icon={GiveIcon}
            text="Je donne"
            text2={widthScreen < 899 ? 'Une fois ou tous les mois' : '- Une fois ou tous les mois'}
            height={50}
          />
        </div>
        <div className="give-text">
          <p className="subtitle">
            Pour faire un don auprès de l&apos;association <span className="span">La Main sur le Cœur</span>, choisissez
            la plateforme qui vous convient :
          </p>
        </div>
      </section>

      <section className="donate-button-container bg-light-red">
        <div className="content">
          <div className="paypal-donate-ctn">
            <img className="paypal-logo donate-logo" src={PayPayLogo} alt="Logo PayPal" width={120} />
            <form action="https://www.paypal.com/donate" method="post" target="_blank">
              <input type="hidden" name="business" value="GME7HA3PJPGPW" />
              <input type="hidden" name="no_recurring" value="0" />
              <input
                type="hidden"
                name="item_name"
                value="Les dons sont destin%C3%A9s %C3%A0 l'association La Main sur le Coeur"
              />
              <input type="hidden" name="currency_code" value="EUR" />
              <input
                type="image"
                className="paypal-donate-button donate-button"
                src="https://www.paypalobjects.com/fr_FR/FR/i/btn/btn_donate_LG.gif"
                border="0"
                name="submit"
                title="PayPal - Le moyen le plus sûr et le plus simple de payer en ligne !"
                alt="Bouton Faites un don avec PayPal"
              />
              <img alt="" border="0" src="https://www.paypal.com/fr_FR/i/scr/pixel.gif" width="1" height="1" />
            </form>
          </div>

          <div className="helloasso-donate-ctn">
            <img className="helloasso-logo donate-logo" src={HelloAssoLogo} alt="Logo HelloAsso" width={150} />
            <iframe
              loading="lazy"
              className="helloasso-donate-button donate-button"
              id="haWidget"
              src="https://www.helloasso.com/associations/la-main-sur-le-coeur-association/formulaires/1/widget-bouton"
              style={{ width: '200px', height: '66px', border: 'none' }}
            ></iframe>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Give;
